<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Controle de Gastos
    </v-card-title>
    <v-card-text>
      <div class="d-flex align-center pt-0">
        <div class="table-v-action-button mt-2 mr-2" @click="doLoad">
          <v-icon>mdi-refresh</v-icon>
          Atualizar
        </div>
      </div>
    </v-card-text>
    <v-card-text class="table-wrap p-0">
      <v-container fluid class="py-0">
        <v-row>
          <v-col :cols="12" class="text-center text-uppercase text-h6">
            Dispêndio
          </v-col>
          <v-col :cols="6">
            <span>Recursos Humanos</span> {{ gastos.rh | toCurrency | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Serviços Técnicos de Terceiros</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Equipamento e Software</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Treinamento</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Obras Civis</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Viagens</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Livros ou Periódicos</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Outros Correlatos</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Material de Consumo</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="6" />
          <v-col :cols="12">
            <strong>Total de Dispêndio {{ gastos.rh | toCurrency }}</strong>
          </v-col>
          <v-col :cols="12" class="text-center text-uppercase text-h6">
            Demais Custos
          </v-col>
          <v-col :cols="12">
            <span>Custo incorrido pela instituição</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="12">
            <span>Valor total repassado para a Instituição</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="12">
            <span>Valor antecipado para o próximo ano</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="12">
            <span>Valor antecipado do ano anterior</span> {{ gastos.rh | toCurrency }}
          </v-col>
          <v-col :cols="12" class="text-center text-uppercase text-h6">
            Totais
          </v-col>
          <v-col :cols="12">
            <strong>Valor gasto no ano base {{ gastos.rh | toCurrency }}</strong>
          </v-col>
          <v-col :cols="12">
            <strong>Total válido para cumprimento de obrigação no ano base {{ gastos.rh | toCurrency }}</strong>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    resource: function () {
      const id = this.$route.params.projetoExternoId || '';
      return this.apiResource(`v1/faturamento/projext/gastos/${id}`);
    },
  },
  data: function () {
    return {
      gastos: {
        rh: 1000,
      },
    };
  },
  methods: {
    doLoad: function () {
      this.resource.get().then((response) => {
        this.gastos = response;
      });
    },
  },
  name: "controle-gastos",
}
</script>

<style lang="scss" scoped>
.theme--light.v-card > .v-card__text {
  color: #212529;
}

.table-wrap {
  background-color: rgb(244, 244, 244);
}

.row {
  border-collapse: collapse;
}

.row,
.col {
  border-bottom: thin solid rgba(0,0,0,.12);
}

.text-center.text-uppercase.text-h6 {
  background-color: #bedefc;
  color: #3e3e3f;
}
</style>
